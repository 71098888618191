import React from "react";
import styles from "../styles/body.css"
import {ProjectCard} from '../Component/ProjectCard'
import {ProjectDetails} from '../Component/ProjectDetails'

export class Project extends React.Component{

    constructor(props){
        super(props)

        this.state={
            showModal: false,
            project: {name:""},
        }

        this.handleToggleModal = this.handleToggleModal.bind(this)
        this.hideModal         = this.hideModal.bind(this)
    }

    handleToggleModal (project){
        this.setState({
            showModal: !this.state.modal,
            project: project
        })
    }

    hideModal(){
        this.setState({
            showModal: false,
            project: {name:""}
        })
    }

    render(){
        return(
            <div className="project-box">
                {
                    this.props.projects.map((item)=>{
                        return <ProjectCard toggleModal={this.handleToggleModal} proyectName={item.name} technologies={item.technologies} link={item.link} project={item}></ProjectCard>;
                    })
                }
                
                {this.state.showModal &&<ProjectDetails showModal={this.state.showModal} hideModal={this.hideModal} project={this.state.project}></ProjectDetails>}
                
            </div>
        )
    }

}