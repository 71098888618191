import React from "react";
import styles from "../styles/body.css"
import {KnowledgeCard} from '../Component/KnowledgeCard'

export class Knowledege extends React.Component{
    render(){
        return(
            <div className="knowledge-box">
                
                <KnowledgeCard technology="ReactNative" level="90"></KnowledgeCard>
                <KnowledgeCard technology="Ruby On Rails" level="90"></KnowledgeCard>
                <KnowledgeCard technology="PHP" level="85"></KnowledgeCard>
                <KnowledgeCard technology="Tableau" level="90"></KnowledgeCard>
                <KnowledgeCard technology="Golang" level="75"></KnowledgeCard>
                <KnowledgeCard technology="ReactJs" level="60"></KnowledgeCard>
                <KnowledgeCard technology="Pythoon" level="50"></KnowledgeCard>

                <KnowledgeCard technology="MongoDB" level="75"></KnowledgeCard>
                <KnowledgeCard technology="SQLServer" level="75"></KnowledgeCard>
                <KnowledgeCard technology="MYSQL" level="80"></KnowledgeCard>
                <KnowledgeCard technology="SQLite" level="80"></KnowledgeCard>
                <KnowledgeCard technology="PostgreSQL" level="80"></KnowledgeCard>
                <KnowledgeCard technology="Oracle 11,12" level="60"></KnowledgeCard>
                

                <KnowledgeCard technology="AWS EC2" level="75"></KnowledgeCard>
                <KnowledgeCard technology="AWS S3" level="50"></KnowledgeCard>
                <KnowledgeCard technology="Google Cloud" level="40"></KnowledgeCard>
            </div>
        )
    }

}