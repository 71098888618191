import React from "react";
import utils from '../styles/utils.css'
import {Job} from './Job'
import {Project} from './Project'
import {Knowledege} from './Knowledge'

export class Body extends React.Component{
    constructor(props){
        super(props)

        this.state={
            selectedCompany: "Muevo",
            companyDevelopedProjects: [],
            jobs: [
                { company: "Muevo", position: "CEO", years: "2021-Actual", projects: [
                        { name: "Android APP", 
                            technologies:"React Native, Android Studio, AdobeXD", link: "",
                            description:"Muevo App is an application for Android designed for people which current job its to drive in diferent routes at the city, so they can earn money carrying a Muevo ad at the rear windshield an saving the driven routes by the application.",
                            images:['MuevoApp2/login.png','MuevoApp2/signup.png','MuevoApp2/signup2.png','MuevoApp2/signup3.png','MuevoApp2/driving.png','MuevoApp2/earnings.png','MuevoApp2/configs.png','MuevoApp2/conditions.jpg']
                        },
                        { name: "IOS APP", 
                            technologies:"React Native, Xcode", link: "",
                            description:"Also created for IOS",
                            images:['MuevoApp2/login.png','MuevoApp2/signup.png','MuevoApp2/signup2.png','MuevoApp2/signup3.png','MuevoApp2/driving.png','MuevoApp2/earnings.png','MuevoApp2/configs.png','MuevoApp2/conditions.jpg']
                        },
                        { name: "Dashboard",
                            technologies:"Ruby on Rails", link: "http://admin.muevoapp.com/",
                            description:"The Dashboard is a web tool where clients can track the contracted vehicles in Muevo, they can check where they rode, how much time and for how many kilometers the ad has been shown.",
                            images:['Dashboard/login.JPG','Dashboard/tracking.JPG','Dashboard/dashboard1.JPG','Dashboard/dashboard2.JPG']
                        },
                        { name: "API", 
                            technologies:"Golang, GIN, Json Web Tokens ",
                            description:"The API feeds the application with the necessary data by validating sessions and resolving requests.",
                            images:['MuevoAPI/golang.png','MuevoAPI/jwt.jpeg','MuevoAPI/mongo.png']
                        },
                        { name: "Processor", 
                            technologies:"Python",
                            description:"The processor is designed to calculate user earnings, daily driven distances, daily driven time and checks if every user achieve the daily goal.",
                            images:['MuevoProcessor/python.png','MuevoProcessor/mongo.png']
                        },
                    ]
                },
                { company: "2Software", position: "CEO", years: "2016-Actual", projects: [
                        { name: "CRM",
                            technologies:"Ruby on Rails", link: "",
                            description:"This project provides a set of tools to ecomerce, in this tool the startups can manage ther clients, products, stock and track their deliverys",
                            images:['CRM/main.JPG','CRM/config.JPG','CRM/puntodeventa.JPG','CRM/reporte.JPG']
                        },
                        { name: "MyTable",
                            technologies:"Ruby on Rails", link: "",
                            description:"This project is a set of tools for restaurants, you can display the menu on tablets and request the dish to the kitchen immediately, know the status of your order and manage the bill.",
                            images:['MyTable/main.JPG','MyTable/menu.JPG','MyTable/ingredients.JPG','MyTable/comanda.JPG']
                        },
                        { name: "ShoppingCart",
                            technologies:"Ruby on Rails", link: "",
                            description:"A Web Page managed by 2Software CRM, designed for ecommerse, in this web page clients can buy, pay and track easyly",
                            images:['CartShop/main.JPG','CartShop/details.JPG','CartShop/cart.JPG','CartShop/payment.JPG']
                        },
                        { name: "Tracking", 
                            technologies:"PHP", link: "",
                            description:"A Web Page managed by 2Software CRM, designed for ecommerse, in this web page clients can buy, pay and track easyly",
                            images:['Tracking/login.JPG','Tracking/main.JPG','Tracking/Reporte.JPG']
                        },
                        { name: "Tracking API", 
                            technologies:"Node JS",
                            description:"An API designed to intercomunicate the PHP tracking project with other information systems",
                            images:[]
                        },
                    ]
                },
                { company: "USAID", position: "Senior Developer", years: "2021-Actual", projects: [
                        { name: "Spending Quality", 
                            technologies:"Tableau, python", link: "",
                            description:"This project is a set of dashboard designed for representate how the health mynistry spend the public resources",
                            images:['USAID/calidadgasto1.JPG','USAID/calidadgasto2.JPG','USAID/calidadgasto3.JPG']
                        },
                        { name: "1000 day window", 
                            technologies:"Tableau", link: "",
                            description:"This project is a set of dashboard designed to represent the result of the supervision of the feeding of children in the first 1000 days after birth.",
                            images:['USAID/v1000d1.JPG','USAID/v1000d2.JPG','USAID/v1000d3.JPG']
                        },
                        { name: "Education Rights", 
                            technologies:"Tableau", link: "",
                            description:"This project is a set of dashboard designed for representate the result of the supervision to public schools in Guatemala about the access to education to the childrens",
                            images:['USAID/educacionpdh1.JPG','USAID/educacionpdh2.JPG','USAID/educacionpdh3.JPG']
                        },
                        { name: "Land Use Planning", 
                            technologies:"PHP, .NET", link: "http://territorialidad.hpp-gt.org/",
                            description:"This project is a set of dashboard designed for representate the distribution of health centers in the whole country",
                            images:['USAID/landplanning1.JPG','USAID/landplanning2.JPG','USAID/landplanning3.JPG']
                        },
                    ]
                },
                { company: "ZGAS", position: "Full-Stack Developer", years: "2020" , projects: [
                    { name: "API", technologies:"Node JS, Sails JS", link: ""},
                ]},
                { company: "CLARO", position: "Project Manager", years: "2017-2020", projects: [
                    { name: "SISPACO", technologies:"-", link: ""},
                    { name: "Mi Claro APP v2", technologies:"Java", link: ""},
                    { name: "ETL", technologies:"Sap Data Services", link: ""},
                ]},
                { company: "INFOM", position: "Junior Developer", years: "2016-2017", projects: [
                    { name: "Credits", technologies:"PHP, Symfony", link: ""},
                    { name: "Wallet", technologies:"PHP, Symfony", link: ""},
                    { name: "RRHH", technologies:"PHP, Symfony", link: ""},
                ]},
                { company: "Freelance", position: "Junior Developer", years: "2015", projects: [
                    { name: "Savings Control", technologies:"Native PHP", link: ""},
                    { name: "Savings Control", technologies:"Native PHP", link: ""},

                ] },
            ]
        }
    }
    
    componentDidMount(){
        this.handleShowProjects("Muevo")
    }

    handleShowProjects = (company) =>{
        let companyDevelopedProjects = []
        this.state.jobs.map((item)=>{
            if(item.company==company){
                companyDevelopedProjects = item.projects
            }
        })
        this.setState({
            selectedCompany: company,
            companyDevelopedProjects: companyDevelopedProjects
        })

    }

    render(){
        return(
            <>
            <div className="row">
                <Job jobs={this.state.jobs} handleShowProjects={this.handleShowProjects}></Job>
                <Project projects={this.state.companyDevelopedProjects}></Project>
            </div>
            <div className="row">
                <Knowledege></Knowledege>
            </div>
            </>
        );
    }
}