import React from "react";
import utils from '../styles/utils.css'
import {Me} from '../Component/Me'
import { ContactData } from "../Component/ContactData";
import { Row, Col } from "react-bootstrap";

export class Header extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            bornDate: '23'
        }
    }

    componentDidMount(){

    }
    
    render(){
        return(
            <Row>
                <Col  xs={12} sm={3} md={3}>
                    <Me></Me>
                </Col>
                <Col  xs={12} sm={9} md={9}>
                    <ContactData property="First Name:" val="Abner Jonathan" type="text"></ContactData>
                    <ContactData property="Last Name:" val="Del Cid Morales" type="text"></ContactData>
                    <ContactData property="Age:" val={this.state.bornDate}   type="text"></ContactData>
                    <ContactData property="Email:" val="abner_jonathan@hotmail.com"  link="abner_jonathan@hotmail.com" type="link"></ContactData>
                    <ContactData property="Phone:" val={"+502 5516-7969"} link="https://ultramsg.com/m/3rfrbdw" type="link"></ContactData>
                    <ContactData property="Whatsapp:" val={"+502 5516-7969"} link="https://ultramsg.com/m/3rfrbdw" type="link"></ContactData>
                    <ContactData property="Linkedin:" val={"www.linkedin.com/abnerdc"} link="https://www.linkedin.com/in/abner-jonathan-del-cid-morales-566097109?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAABs-G14Bq1xBE9ppJGm7UW4TrTV1q09bC4E&lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3BpPkjMdVaRjq25kZBpO47CQ%3D%3D" type="link"></ContactData>
                </Col>
            </Row>
        );
    }
}