import React from "react";
import styles from '../styles/body.css'
import { ProgressBar } from "react-bootstrap";

export class KnowledgeCard extends React.Component{
    constructor(props){
        super(props)
    }

    render(){
        return(
            <div className="knowledge-card">
                <div className="col-md-1">{this.props.technology}</div>
                <div className="col-md-10 progress-bar-fix"><ProgressBar animated  now={this.props.level} label={`${this.props.level}%`} ></ProgressBar></div>
            </div>
        )
    }
}