import React from "react";
import styles from "../styles/body.css"
import {JobCard} from '../Component/JobCard'

export class Job extends React.Component{
    constructor(props){
        super(props)
    }

    render(){
        return(
            <div className="job-box">
                {this.props.jobs.map((item) =>{
                    return <JobCard handleShowProjects={this.props.handleShowProjects} company={item.company} position={item.position} proyects={item.projects.length} years={item.years} ></JobCard>
                    }
                )} 
            </div>
        )
    }

}