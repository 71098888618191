import { useEffect, useState } from 'react';
import { Modal, Carousel, Button, Row, Col } from 'react-bootstrap';

export function ProjectDetails(props) {
  const [colMd, setColMd] = useState(8)

  useEffect(()=>{
    if(props.project.name == "Android APP" || props.project.name == "IOS APP"){
      setColMd(4);
    }
  },[])
  return (
    <>
      <Modal show={props.showModal} fullscreen>
        <Modal.Header closeButton onHide={() => props.hideModal({ name: "" })} >
          <Modal.Title>{props.project.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Carousel>

            {props.project.images.map((element) => {
              return (
                <Carousel.Item>
                  <Row className="justify-content-md-center">
                    <Col xs={12} sm={4} md={colMd}>
                      <img
                        className="projectImage"
                        src={require("../Assets/" + element)}
                      />
                    </Col>
                  </Row>
                </Carousel.Item>
              )
            })}
          </Carousel>
        </Modal.Body>
        <Modal.Footer>
        <Carousel.Caption className='projectDescription'>
            <h3>{props.project.name}</h3>
            <p>{props.project.description}</p>
          </Carousel.Caption>
          <Button variant="secondary" onClick={() => props.hideModal({ name: "" })} >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}