import React from "react";
import styles from "../styles/body.css";

export class JobCard extends React.Component{
    constructor(props){
        super(props)
    }

    render(){
        return(
            <div className="job-card">
                <div className="card-item">{this.props.company}</div>
                <div className="card-item">{this.props.position}</div>
                <div className="card-item">{this.props.years}</div>
                <div className="card-item"><button className="btn btn-info" onClick={()=>this.props.handleShowProjects(this.props.company)}>Show Projects({this.props.proyects})</button></div>
            </div>
        )
    }
}