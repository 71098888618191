import React from "react";
import style from "../styles/me.css"
import {Image, Row, Col} from 'react-bootstrap'

export class Me extends React.Component{
    constructor(props){
        super(props)
    }

    render(){
        return(
            <Row className="justify-content-md-center">
                <Col>
                    <Image className="my-pic" src={require('../Assets/Me.jpg')}></Image>
                </Col>
            </Row>
        )
    }
}