import React from "react";
import styles from "../styles/body.css";

export class ProjectCard extends React.Component{
    constructor(props){
        super(props)
    }

    render(){
        return(
            <div className="project-card">
                <div className="project-card-item">{this.props.proyectName}</div>
                <div className="project-card-item">{this.props.technologies}</div>
                { this.props.project.images && this.props.project.images.length > 0 ? (
                    <div className="project-card-item"><button className="btn btn-info" onClick={()=>this.props.toggleModal(this.props.project)} >Show</button></div>
                ) : (
                    <div className="project-card-item"><button className="btn btn-warning" >Link Pending</button></div>
                )
                }
                
            </div>
        )
    }
}